/* .center-content {
    justify-content: center;
    align-items: center;
}

.container{
    display: flex;
    align-items: center;
  }
  
  .border{
    border-bottom: 1px groove white;
    width: 97%;
    margin: 1%;
  }

  .content {
    padding: 0 0 0 0;
  } */

  .nav-social {
    display: grid;
    grid-template-columns: repeat(5, auto);
    list-style: none;
    text-align: end;
    justify-content: end;
}
