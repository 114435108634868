.im {

    object-fit: scale-down;

}

.under {
    text-decoration: none;
    border-bottom: 1px solid rgb(226, 7, 7);
  }
  u {
    text-decoration: none;
    border-bottom: 2px solid black;
  }
  
/* 
.fill {object-fit: fill;}
.contain {object-fit: contain;}
.cover {object-fit: cover;}
.scale-down {object-fit: scale-down;}
.none {object-fit: none;} */