

/* .mapp {
    margin-left: 20%;
} */

ul {
    list-style-type:none;
}

a {
    text-decoration:none;
 }
 
.container {
    display: flex;
    flex-direction: row;
}

.gridBack {
    background-color: 'rgb(205, 222, 252)';
}
.NavBarItems {
    background: linear-gradient(90deg,(110,94,254)0%,(73,63,252,1) 100%);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-left: 100px;
    font-size: 1.2rem;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 20px;
    list-style: none;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.contactdet {
    display: flex;
    align-items: center;
    margin-left: 20%;
    margin-right: 30%;
}

.nav-links {
    color : black;
    text-decoration: none;
    padding :1.0rem 2rem;
}

/* ul {
    padding: 0;
    margin: 0;
    display: block;
    list-style-type: none
  }
  li {
    display:list-item;
    width: 100%; 
    word-break: break-all; 
  } 
  */
