.NavBarItems {
    background: linear-gradient(90deg,(110,94,254)0%,(73,63,252,1) 100%);
    height: 60px; 
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
}

.nav-menu {
    display: inline-block;
    grid-template-columns: repeat(7, auto);
    grid-gap: 20px;
    list-style: none;
    text-align: center;
    width: 80vw;
    justify-content: center;
}

.logo {
    align-content: center;
}

.nav-links {
    color : black;
    text-decoration: none;
    margin : 50px;
}

.links-menu {
    display: flex;
    grid-template-columns: repeat(5, auto);
    grid-gap: 10px;
    list-style: none;
    justify-content: center;
}

/* .menu-icon {
    display : block;
    position: absolute;
    top:0;
    left:0;
    cursor: pointer;
} */

/* @media screen and (max-width: 960px) {
    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height:500px;
        position: absolute;
        top:80px;
        left:-100%;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .nav-menu.active {
        background: #fff;
        left : 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }
} */

.nav-social {
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 20px;
    list-style: none;
    text-align: center;
    justify-content: end;
}

